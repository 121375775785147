import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../containers/common/actions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  CircularProgress,
} from "@material-ui/core";

function CustomDialogBox(props) {
  const dispatch = useDispatch();
  const { confirmModal } = useSelector(state => state.common);
  const {
    title = "",
    dialogtext = "",
    confirmAction = () => {},
    description = "",
    confirmBtnLabel = "",
  } = confirmModal || {};
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(actions.closeDeleteModal());
  };

  const confirmedAction = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    dispatch(actions.closeDeleteModal());
    confirmAction();
  };

  const { open } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} className="cus-dialog py-3">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogtext}
          <br />
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="dialog-btns">
          <Button
            onClick={handleClose}
            className="button-btn btn-custom-black mr-3"
            disabled={isLoading}>
            No
          </Button>
          <Button
            onClick={confirmedAction}
            className="button-btn btn-custom-primary"
            disabled={isLoading}>
            {isLoading ? <CircularProgress size={18} color="white" /> : confirmBtnLabel || "Yes"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialogBox;
