import moment from "moment";
import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from "../../../utils";

const ADD_CUS_MEMBER = "/api/customer/property/add-member";

export function updateCustomerReferralsAPI(data) {
  return apiPut(`/api/customer-referrals/update-referrals-status`, data);
}

export function getCustomerReferralsAPI(filters) {
  console.log("filters", filters);
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["created_at"].includes(key)) {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.created_at[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.created_at[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else {
      arr.push({ key, value: encodeURIComponent(value), type: "single" });
    }
  }

  console.log("JSON.stringify(arr)", JSON.stringify(arr));

  return apiGet(
    `/api/customer-referrals/list?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`
  );
}

export function getAllCoustomerAPI(filters) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value: encodeURIComponent(value), type: "single" });
    }
  }
  return apiGet(
    `/api/customer/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`
  );
}

export function getCustomerDetailAPI(id) {
  return apiGet(`/api/customer/get-info?customer_id=${id}&deviceType=${getDeviceType()}`);
}

export function deleteCustomerAPI(id) {
  return apiDelete(`/api/customer/delete?customer_id=${id}`);
}

export function getPropertyDetailsAPI(id) {
  return apiGet(`/api/customer/property/get-info?property_id=${id}`);
}

export function addPropertyMemberAPI(data) {
  return apiPost(ADD_CUS_MEMBER, data);
}

export function updatePropertyDetailAPI(data) {
  const { updatedRegisteredAddress = {} } = data;
  return apiPut("/api/customer/property/update-details", updatedRegisteredAddress);
}

export function updateCustomerDetailAPI(data) {
  const { id = "", customerDetail = {} } = data;
  const { first_name = "", last_name = "", phone_no = "", email } = customerDetail || {};
  let obj = {
    customer_id: id,
    first_name,
    last_name,
    phone_no,
    email,
    // premium
  };
  return apiPut("/api/customer/update", obj);
}

export function electricityRateHistoryAPI(id) {
  return apiGet(`/api/customer/property/tariff-history?property_id=${id}`);
}

export function deletePropertyAPI(id) {
  return apiDelete(`/api/customer/property/delete?property_id=${id}`);
}

export function getCusLoginPayloadForTableAPI(filters) {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at", id = "" } = filters || {};
  return apiGet(
    `/api/customer/login-data?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`
  );
}

export function getCusAdminPropertiesForTableAPI(filters) {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at", id = "" } = filters || {};
  return apiGet(
    `/api/customer/property/list?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`
  );
}

export function getCusSharedPropertiesForTableAPI(filters) {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at", id = "" } = filters || {};
  return apiGet(
    `/api/customer/property/shared-list?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`
  );
}

export function getCusPropertyMembersForTableAPI(filters) {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at", id = "" } = filters || {};
  return apiGet(
    `/api/customer/property/member?property_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`
  );
}

export function getCusPropertyDevicesForTableAPI(filters, device_type) {
  let { page = 1, limit = 25, orderBy = "desc", sortBy = "created_at" } = filters || {};
  return apiGet(
    `/api/customer/property/devices?property_id=${
      filters.id === undefined ? filters.propertyId : filters.id
    }&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${device_type}`
  );
}

export function getCustomerEmailHistoryAPI(id) {
  return apiGet(`/api/customer/email-history?customer_id=${id}`);
}

export function deleteMemberAPI(obj) {
  return apiDelete(`/api/customer/property/remove-member`, obj);
}

export function downloadCSVAllCustomersAPI() {
  return apiGet(`/api/export/customers?deviceType=${getDeviceType()}`);
}

export function downloadCSVTrialCustomersAPI() {
  return apiGet("/api/export/feedback");
}

export function getPropertyTimelineHistoryAPI(id) {
  return apiGet(`/api/customer/property/timeline?property_id=${id}`);
}

export function getTrialHistoryAPI(id, syncType) {
  return apiGet(`/api/customer/feedback-history?customer_id=${id}&sync_type=${syncType.synctype}`);
}

export function downloadCSVTrialforSingleCustomerAPI(id) {
  return apiGet(`/api/customer/export-feedback?customer_id=${id}`);
}

export function downloadAutoSyncFeedbackFormAPI(id) {
  return apiGet(`/api/customer/export-feedback-email?customer_id=${id}`);
}

export function downloadAutoSyncFormCsvAPI() {
  return apiGet("/api/export/feedback-all");
}
